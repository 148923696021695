// field iso6391 is language code according to ISO639-1 standard and uses 2 letters
// field iso6392 is language code according to ISO639-2 standard and uses 3 letters
// array items are using format defined in LangData interface
export const LANGUAGES = [
  {
    name: {
      en: 'Swedish',
      sv: 'Svenska',
      no: 'Svensk',
      da: 'Svensk',
      de: 'Schwedisch',
      fi: 'Ruotsin',
    },
    iso6391: 'sv',
    iso6392: 'swe'
  },
  {
    name: {
      en: 'Norwegian',
      sv: 'Norska',
      no: 'Norsk',
      da: 'Norsk',
      de: 'Norwegisch',
      fi: 'Norja',
    },
    iso6391: 'no',
    iso6392: 'no'
  },
  {
    name: {
      en: 'English',
      sv: 'Engelsk',
      no: 'Engelsk',
      da: 'Engelsk',
      de: 'Englisch',
      fi: 'Englanti',
    },
    iso6391: 'en',
    iso6392: 'eng'
  },
  {
    name: {
      en: 'Danish',
      sv: 'Danska',
      no: 'Dansk',
      da: 'Dansk',
      de: 'Dänisch',
      fi: 'Tanska',
    },
    iso6391: 'da',
    iso6392: 'dan'
  },
  {
    name: {
      en: 'Finnish',
      sv: 'Finska',
      no: 'Finsk',
      da: 'Finsk',
      de: 'Finnisch',
      fi: 'Suomi',
    },
    iso6391: 'fi',
    iso6392: 'fin'
  },
  {
    name: {
      en: 'German',
      sv: 'Tyska',
      no: 'Tysk',
      da: 'Tysk',
      de: 'Deutsch',
      fi: 'Saksa',
    },
    iso6391: 'de',
    iso6392: 'deu'
  }
];

export type TranslationObject = {
  [key in TranslationKeys]?: string;
};

export enum TranslationKeys {
  EN = 'en',
  SV = 'sv',
  NO = 'no',
  DE = 'de',
  DA = 'da',
  FI = 'fi'
  // ES = 'es',
  // FR = 'fr',
  // NL = 'nl'
}

export enum LanguageByCode {
SE = 'SV',
NO = 'NO',
US = 'EN',
DA = 'DA',
DE = 'DE',
FI = 'FI'
}

import {
  LandingPage,
  Country,
  IEnterprise,
  EnterpriseCompany,
  IOrganization,
  WhyWorkWithUsSection,
  Intro,
  WhyWorkWithUs,
  ICompany
} from '../models/company.model';
import { UniversalJobShortInfo } from '../models/universa-job.model';
import { LanguageByCode, TranslationKeys } from '../models/translation-keys.model';

export const ALL_JOBS_DOMAIN = 'jobs';
export class Organization {

  candidatePortalLanguage?: TranslationKeys;
  companies: EnterpriseCompany[] = [];
  countries: Country[] = [];
  facebookProfileUrl: string;
  guid: string;
  id: number;
  instagramProfileUrl: string;
  intro: Intro;
  isEnterprise: boolean;
  landingPage: LandingPage;
  language?: TranslationKeys;
  link?: string;
  linkedInProfileUrl: string;
  logo: string;
  name: string;
  possibleLanguages?: TranslationKeys[];
  primaryColor: string;
  termsAndConditions: string;
  useIntro: boolean;
  useLandingPage: boolean;
  whyWorkWithUsSections: WhyWorkWithUsSection[] = [];
  useWhyWorkWithUs: boolean;
  universalJobs: UniversalJobShortInfo[];
  useUniversalJob?: boolean;
  isAllJobs?: boolean;
  twitterProfileUrl: string;
  tiktokProfileUrl: string;
  country: {id: number}

  constructor(organization: IOrganization) {
    this.guid = organization.guid;
    this.id = organization.id;
    this.name = organization.name;
    this.primaryColor = organization.primaryColor;
  }

  setWhyWorkWithUsSections(whyWorkWithUs: WhyWorkWithUs): void {
    const {sections, id, ...firstSection} = whyWorkWithUs;
    this.whyWorkWithUsSections = [firstSection].concat(sections || []);
  }
}

export class Enterprise extends Organization {

  constructor(enterprise: IEnterprise) {
    super(enterprise);

    this.isEnterprise = true;
    let useUniversalJob = false;
    const universalJobs = [];
    const languages = [];

    enterprise.companies
      .forEach((company: EnterpriseCompany) => {
        const countryExists = this.countries.find((country: Country) => country.id === company.location.country.id);

        if (company.active && !countryExists) {
          const country: Country = { ...company.location.country, language: company.language };

          this.countries.push(country);
          languages.push(company.language);
        }

        useUniversalJob = useUniversalJob || company.useUniversalJob;

        if (company.useUniversalJob) {
          universalJobs.push(company.universalJob);
        }
      });

    if (!languages.includes('en')) {
      languages.push('en');
    }

    this.companies = enterprise.companies;
    this.intro = enterprise.enterpriseIntro;
    this.logo = enterprise.enterpriseLogo;
    this.useLandingPage = enterprise.useEnterpriseLandingPage;
    this.landingPage = enterprise.enterpriseLandingPage;

    this.language = TranslationKeys.EN;
    this.possibleLanguages = languages;
    this.useIntro = enterprise.useEnterpriseIntro;
    this.useUniversalJob = useUniversalJob;
    this.universalJobs = universalJobs;
    this.useWhyWorkWithUs = enterprise.useEnterpriseWhyWorkWithUs;

    if (this.useWhyWorkWithUs) {
      this.setWhyWorkWithUsSections(enterprise.enterpriseWhyWorkWithUs);
    }
  }
}

export class Company extends Organization {

  constructor(company: ICompany) {
    super(company);

    this.isEnterprise = false;
    this.logo = company.companyLogo;
    this.intro = company.companyIntro;
    this.termsAndConditions = company.termsAndConditions;
    this.useLandingPage = company.useCompanyLandingPage;
    this.landingPage = company.companyLandingPage;
    this.useIntro = company.useCompanyIntro;
    this.useWhyWorkWithUs = company.useWhyWorkWithUs;
    this.universalJobs = [company.universalJob];
    this.useUniversalJob = company.useUniversalJob;
    this.facebookProfileUrl = company.facebookProfileUrl;
    this.instagramProfileUrl = company.instagramProfileUrl;
    this.linkedInProfileUrl = company.linkedInProfileUrl;
    this.tiktokProfileUrl = company.tiktokProfileUrl;
    this.twitterProfileUrl = company.twitterProfileUrl;
    this.language = company.language;
    this.candidatePortalLanguage = company.candidatePortalLanguage;
    this.link = company.link;
    this.country = company.location.country;

    if (this.useWhyWorkWithUs) {
      this.setWhyWorkWithUsSections(company.whyWorkWithUs);
    }
  }
}

export class AllJobsOrganization extends Organization {

  constructor(countries: Country[]) {
    super({id: null, guid: null, name: 'Higher jobs', primaryColor: '#eb5929'});

    this.isAllJobs = true;
    this.intro = null;

    this.logo = `${window.location.origin}/assets/images/higher-logo.svg`;

    this.useLandingPage = false;
    this.landingPage = null;

    this.countries = [];
    countries
      .forEach((country: Country) => {
        const item: Country = { ...country, language: TranslationKeys[LanguageByCode[country.code]] };
        this.countries.push(item);
      });

    // for alljobs page default language is swedish
    this.language = TranslationKeys.SV;
    //only swedish and english are allowed in Casting
    this.possibleLanguages = Object.values(TranslationKeys).filter((lng) => lng === 'sv' || lng === 'en');
    //only Sweden is allowed in Casting
    this.country = { id: countries.filter((country: Country) => country.code === 'SE')[0].id };
    this.useIntro = false;
    this.useUniversalJob = false;
    this.universalJobs = [];
    this.useWhyWorkWithUs = false;
  }
}

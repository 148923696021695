import { IApplication } from '../models/application.model';
import { JobApplication } from '../models/job-application.model';
import { Job } from '../models/job.model';

export class Application {
  jobApplication: JobApplication;
  jobInfo: Job;
  inactiveJob?: boolean;
  candidateDataErased?: boolean;
  isUniversalJob?: boolean;

  constructor(jobApplicationData: IApplication) {
      this.jobInfo = jobApplicationData.job || jobApplicationData.universalJob;
      this.isUniversalJob = !!jobApplicationData.universalJob;
      this.inactiveJob = false;
      this.candidateDataErased = false;

      this.jobApplication = {
        id: jobApplicationData.id,
        guid: jobApplicationData.guid,
        knockoutQuestions: jobApplicationData.knockoutQuestions,
        inhouseQuestion: jobApplicationData.inhouseQuestion,
        businessQuestions: jobApplicationData.businessQuestions,
        videoQuestions: jobApplicationData.videoQuestions,
        orderOfQuestionsModules: jobApplicationData.orderOfQuestionsModules,
        applicationComplete: jobApplicationData.applicationComplete,
        currentQuestionsModule: jobApplicationData.currentQuestionsModule,
        nextQuestion: jobApplicationData.nextQuestion,
        passBusinessQuestions: jobApplicationData.passBusinessQuestions,
        videoQuestionsComplete: jobApplicationData.videoQuestionsComplete,
        numberOfSentApplicationLinkSMSs:
          jobApplicationData.numberOfSentApplicationLinkSMSs,
        candidate: {
          id: jobApplicationData.candidate.id,
          guid: jobApplicationData.candidate.guid,
          name: jobApplicationData.candidateAppliedWithName,
        },
        continueApplication: jobApplicationData.continueApplication,
        businessQuestionsScore: jobApplicationData.businessQuestionsScore,
      };
  }

}
